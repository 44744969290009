import React from 'react';
import "./App.css";
import "./Application/css/Components.css";

function App() {
  return (
    <div className="back">
      <div className="absolute left-[32%] sm:left-3">
        <h1 className="text-[70px] sm:text-[60px] text-red text-center title-font mt-[200px] sm:mt-20">Mantel Corp Ltd</h1>
        <p className="text-[30px] sm:text-[25px] font-medium text-center text-primary">For orders contact us :</p>
        <p className="text-[30px] sm:text-[25px] font-medium text-center text-primary">Whatsapp / Text</p>
        <p className="text-[30px] sm:text-[25px] font-medium text-center text-primary">Baseera : <a href="tel:07440629847" className="hover-underline-animation">07440629847</a></p>
        <p className="text-[30px] sm:text-[25px] text-center">Email : <a href="mailto:orders@mantelcorp.co.uk" className="hover-underline-animation">orders@mantelcorp.co.uk</a></p>
      </div>
    </div>
  );
}

export default App;
